<template>
    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
        <left-bar-comp>
            <sports-left-bar-comp></sports-left-bar-comp>
        </left-bar-comp>

        <div class="content_section">
            <div class="content">
                <user-info-links-comp></user-info-links-comp>
                <div class="board_list">
                    <div class="logo_panel" style="padding: 20px">
                        <div class="logo">
                            <img src="../../assets/images/logo/oneplay/oneplay_logo.png">
                        </div>
                        <div class="ment line mt10">
                            <span style="color: #079e39">원플레이</span>에 오신것을 환영합니다.
                        </div>
                    </div>
                    <div class="item_panel">
                        <div class="myitem">
                            <h4 class="item_title">보유아이템</h4>
                            <div  class="item1">
                                <div>
                                    <img src="../../assets/images/item/oneplay/item_shop_4.png" style="width: 60px">
                                    참여취소권
                                </div>
                                <div style="height: 40px;line-height: 40px">
                                    {{$store.state.userInfo.itemcancelbet|comma}} 개
                                </div>
                            </div>
                            <div  class="item1">
                                <div>
                                    <img src="../../assets/images/item/oneplay/item_shop_5.png" style="width: 60px">
                                    쪽지발송 100통
                                </div>
                                <div style="height: 40px;line-height: 40px">
                                    {{$store.state.userInfo.itemsendmessage100pack|comma}} 개
                                </div>
                            </div>
                            <div  class="item1">
                                <div>
                                    <img src="../../assets/images/item/oneplay/item_shop_6.png" style="width: 60px">
                                    쪽지발송 250통
                                </div>
                                <div style="height: 40px;line-height: 40px">
                                    {{$store.state.userInfo.itemsendmessage250pack|comma}} 개
                                </div>
                            </div>

                        </div>
                        <div class="buy_history">
                            <h4 class="item_title">최근구매내역</h4>
                            <table class="table">
                                <tr>
                                    <th></th>
                                    <th>날짜</th>
                                    <th>내역</th>
                                </tr>
                                <tr v-for="(item,index) in buyItemList" :key="index">
                                    <td style="box-sizing: border-box;padding-top: 20px">
                                        <img :src="require(`@/assets/images/item/oneplay/${item.itemId}.png`)" style="width: 60px;">
                                    </td>
                                    <td>{{item.createTime|datef('MM월 DD일')}}</td>
                                    <td>{{item.content}}</td>
                                </tr>
                            </table>
                        </div>
                        <div class="use_history">
                            <h4 class="item_title">최근사용내역</h4>
                            <table class="table">
                                <tr>
                                    <th></th>
                                    <th>날짜</th>
                                    <th>내역</th>
                                </tr>
                                <tr v-for="(item,index) in useItemList" :key="index">
                                    <td style="box-sizing: border-box;padding-top: 20px">
                                        <img :src="require(`@/assets/images/item/oneplay/${item.itemId}.png`)" style="width: 60px;">
                                    </td>
                                    <td>{{item.createTime|datef('MM월 DD일')}}</td>
                                    <td>{{item.content}}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <router-link tag="button" to="/item_shop" class="btn03" style="margin: 20px auto;padding: 10px 50px">아이템 구매</router-link>


                </div>
            </div>
        </div>

        <!-- right 메뉴 -->
        <right-bar-comp>
            <right-bar-banner-comp slot="rightBanner"></right-bar-banner-comp>
        </right-bar-comp>

        <foot-comp></foot-comp>
    </div>

</template>

<script>
    import SubTitle from "../../components/SubTitle";
    import RightBarComp from "../../components/RightBarComp";
    import {
        buyItemHistory,
        deleteApply,
        getApplyCashList,
        logout,
        rechargeCash,
        setDenyLogin,
        setQuote, useItemHistory
    } from "../../network/userRequest";
    import sportsConst from "../../common/sportsConst";
    import LeftBarComp from "../../components/LeftBarComp";
    import FootComp from "../../components/FootComp";
    import TopbarComp from "../../components/TopbarComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import {RECEIVE_SPORTS_CART_ITEM_DEL_ALL} from "../../store/mutation-types";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import ShopLinksComp from "../../components/links/ShopLinksComp";
    import UserInfoLinksComp from "../../components/links/UserInfoLinksComp";

    export default {
        name: "myitems",
        components: {
            UserInfoLinksComp,
            ShopLinksComp,
            SportsLeftBarComp,
            RightBarBannerComp, TopbarComp, FootComp, LeftBarComp, RightBarComp, SubTitle
        },
        data() {
            return {
                sportsConst,
                buyItemList:[],
                useItemList:[],
            }
        },
        methods: {
            buyItemHistory(){
                buyItemHistory().then(res=>{
                    if(res.data.success){
                        this.buyItemList = res.data.data
                    }
                })
            },
            useItemHistory(){
                useItemHistory().then(res=>{
                    if(res.data.success){
                        this.useItemList = res.data.data
                    }
                })
            }
        },
        created() {
            if (!this.$store.state.isLogin) {
                this.$swal({
                    title: '로그인이 필요한 서비스 입니다.',
                    type: 'warning',
                    showCancelButton: false,
                    showConfirmButton: true
                })
                this.$router.push('/login')
                return false;
            }
            this.buyItemHistory()
            this.useItemHistory()

        },
        mounted() {

        }
    }
</script>

<style scoped>
    @import url("../../assets/css/reex.css");

    .item_panel {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .myitem {
        width: 33.3%;
        box-sizing: border-box;
        padding: 10px;
    }

    .buy_history {
        width: 33.3%;
        box-sizing: border-box;
        padding: 10px;
    }

    .use_history {
        width: 33.3%;
        box-sizing: border-box;
        padding: 10px;
    }

    .item_title {

        margin: 10px 0;
        background: linear-gradient(to bottom, rgba(61, 61, 61, 1) 0%, rgba(54, 54, 54, 1) 100%);
        box-shadow: 2px 2px 5px #212529;
        line-height: 40px;
        text-align: left;
        box-sizing: border-box;
        padding: 0 10px;

    }
    .item1{
        box-sizing: border-box;padding: 10px;border: 1px solid #0d0d0d;margin-bottom: 10px;
    }

    @media screen and (max-width: 1024px) {
        .myitem {
            width: 100%!important;
            box-sizing: border-box;
            padding: 10px;
        }

        .buy_history {
            width: 100%!important;
            box-sizing: border-box;
            padding: 10px;
        }

        .use_history {
            width: 100%!important;
            box-sizing: border-box;
            padding: 10px;
        }

    }
</style>